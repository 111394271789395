import React, { Component } from 'react'
import './index.less'
class MemberShip extends Component {
    constructor (props) {
        super(props)
        this.state = {
            
        }
      }
    goOutLink(url){
        window.open(url,'_blank')
    }
    Home(){
        if(this.props.backHome!==undefined){
            this.props.backHome()
        }
    }
    render() {
        return (
            <div className='member animate__animated'>
                <div className='back-home' onClick={()=>this.Home()}></div>
                <div className='title animate__animated animate__fadeInUp'>
                    <div>WELCOME TO INFIBLUE</div>
                    <div>THE BEST METAVERSE GAME</div>
                </div>
                <div className='login animate__animated animate__fadeIn'>
                    <div className='tip'>
                        <div className='login-in'>LOG IN</div>
                        <div className='right'>
                            <div className='new'>NEW USER? </div>
                            <div className='sign' onClick={() => this.goOutLink('https://infiblue.vip/membership/#/pages/register/register') }>SIGN UP</div>
                        </div>
                    </div>
                    <div className='email'>
                        <input className='input'></input>
                    </div>
                    <div className='ibutton next' onClick={() => this.goOutLink('https://infiblue.vip/membership/#/pages/login/login1') }></div>
                </div>
            </div>
        )
    }
}
export default MemberShip